<template>
  <div id="assessmentSummary">
    <div
      class="
        font-headblue
        fontsize-h2
        weight-500
        mb-4
        d-flex
        justify-content-between
      "
    >
      <div>
        <iconify icon="ant-design:pie-chart-filled" />
        สรุปผลการประเมินคุณภาพระบบประปาหมู่บ้าน 5 เกณฑ์
        <span class="font-grayicon fontsize-h6"
          >อัพเดตล่าสุด {{ modifyDate }}</span
        >
      </div>
      <div>
        <span class="fontsize-h6"
          >ผู้ทำแบบประเมิน :
          <b>{{ personCreated.name || "xxxxxxxxxxxxx" }}</b></span
        >
      </div>
    </div>

    <div class="box-shadow p-4 mb-5">
      <div class="d-flex justify-content-between mb-4">
        <div class="weight-700">
          ตารางสรุปผลการประเมิน
          <span class="weight-500 font-grayicon">{{ title }}</span>
        </div>
        <div>
          <!-- <button
            type="submit"
            class="btn btn-gray text-left mr-1"
            @click="isShowModal = true"
          >
            <iconify icon="ant-design:info-circle-outlined" class="mb-1 mx-2" />
            หลักเกณฑ์การประเมิน
          </button> -->
          <button class="btn btn-submit mx-3" @click="viewSuggestion">
            <iconify icon="ant-design:info-circle-outlined" class="mb-1 mr-2" />
            ข้อแนะนำหลังการประเมิน
          </button>
          <button
            type="submit"
            class="btn btn-addlist text-left"
            @click="webpagePrint"
          >
            <iconify icon="feather:printer" class="mb-1 mx-2" />
            พิมพ์ผลการประเมิน
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <div class="box-shadow">
            <div class="fontsize-sm">
              <div>การประเมินคุณภาพระบบประปา</div>
            </div>
            <bar-chart-1 :series="series" :result="chartResult"></bar-chart-1>
          </div>
        </div>
        <div class="col-9">
          <b-table
            id="my-table"
            class="table-custom box-shadow mb-4 fontsize-sm"
            hover
            borderless
            :items="items"
            :fields="fields"
          >
            <template v-slot:empty="">
              <div class="text-center">Empty</div>
            </template>
            <template v-slot:head()="data">
              <div
                :class="
                  data.field.align === 'left'
                    ? 'text-left'
                    : data.field.align === 'right'
                    ? 'text-right'
                    : 'text-center'
                "
              >
                {{ data.label }}
              </div>
            </template>

            <template v-slot:head(index)="data">
              <div class="text-center">
                {{ data.label }}
              </div>
            </template>
            <template v-slot:cell()="data">
              <div
                v-if="data.item.alignRow !== undefined"
                :class="
                  data.item.alignRow === 'left'
                    ? 'text-left'
                    : data.item.alignRow === 'right'
                    ? 'text-right'
                    : 'text-center'
                "
              >
                {{ data.value }}
              </div>
              <div
                v-else
                :class="
                  data.field.align === 'left'
                    ? 'text-left'
                    : data.field.align === 'right'
                    ? 'text-right'
                    : 'text-center'
                "
              >
                {{ data.value }}
              </div>
            </template>
            <template v-slot:cell(statusText)="data">
              <div>
                <iconify
                  class="fontsize-h5"
                  :icon="getIcon(data.item.assessment)"
                  :class="getColorIcon(data.item.assessment)"
                />
                {{ data.item.statusText }}
              </div>
            </template>

            <template #custom-foot="">
              <b-tr class="footer-color">
                <b-th></b-th>
                <b-th>รวมทั้งหมด 5 ด้าน</b-th>
                <b-th>{{ summary.score }}</b-th>
                <b-th class="text-center">{{ summary.percent }}</b-th>
                <b-th
                  ><iconify
                    :icon="getIcon(summary.assessment)"
                    :class="getColorIcon(summary.assessment)"
                    class="mr-1 fontsize-h5"
                  /><span class="weight-500">{{
                    summary.assessmentText
                  }}</span></b-th
                >
              </b-tr>
            </template>
          </b-table>
        </div>
      </div>
    </div>

    <div class="row my-3 fontsize-sm">
      <div class="col-6 mt-4" v-for="dan in Dan" :key="dan.dan">
        <div class="blue-box">
          <div class="header">
            <div class="d-flex justify-content-between align-items-center">
              <div class="weight-700">{{ dan.danText }}</div>
              <div class="custom-bagde">
                <b-badge
                  ><iconify
                    :icon="getIcon(dan.statusTextSymbol)"
                    :class="getColorIcon(dan.statusTextSymbol)"
                    class="mr-1 fontsize-h6"
                  />{{ dan.statusText }}</b-badge
                >
              </div>
            </div>
          </div>
          <div class="content p-0">
            <div
              v-for="(data, index) in dan.list"
              :key="`surfaceWater-${index}`"
              class="m-0 p-3"
              :class="{ 'bg-item': !(index % 2) }"
            >
              <div class="assess-list align-items-center">
                <span class="ml-4" v-html="data.textDisplay"></span>
              </div>
            </div>
            <div
              v-if="dan.list.length === 0"
              class="align-items-center"
              style="min-height: 100%"
            >
              <div class="p-3">-</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box-shadow p-4 mb-3 mt-3">
      <div>
        <div class="weight-700 mb-3">
          <font-awesome-icon
            class="mr-2"
            :icon="['fas', 'comment-alt']"
          />ข้อคิดเห็นและข้อเสนอแนะ
        </div>
        <div class="pl-3">{{ personContact.feedback }}</div>
      </div>
      <hr />
      <div>
        <div class="weight-700 mb-3">
          <font-awesome-icon
            class="mr-2"
            :icon="['fas', 'address-book']"
          />ผู้ให้ข้อมูล
        </div>
        <div v-if="personContact.dataContact !== null">
          <div
            v-for="(data, index) in personContact.dataContact"
            :key="`person-${index}`"
          >
            <div class="row mb-3">
              <div class="col-4">
                <span>ชื่อ - นามสกุล : </span
                ><span class="weight-700">{{ data.name }}</span>
              </div>
              <div class="col-2">
                <span>ตำแหน่ง : </span
                ><span class="weight-700">{{ data.position }}</span>
              </div>
              <div class="col-2">
                <span>หน่วยงาน : </span
                ><span class="weight-700">{{ data.department }}</span>
              </div>
              <div class="col-2">
                <span>เบอร์โทร : </span
                ><span class="weight-700">{{ data.phone }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="pl-3">-</div>
      </div>
    </div>

    <div v-if="isAdmin === false" class="mt-4 info-alert">
      <div class="weight-700 fontsize-h5">
        <iconify
          icon="ant-design:info-circle-outlined"
          class="font-systemblue fontsize-h1"
        />
        ต้องการทำแบบประเมินเพิ่มเติม?
      </div>
      <div class="ml-4">
        ท่านต้องการทำการประเมินระบบประปาแหล่งอื่น
        ภายในหมู่บ้านเดียวกันเพิ่มหรือไม่?
        <span @click="goBackToAssessment" class="link-url cursor-pointer"
          >ฉันต้องการทำเพิ่มเติม</span
        >
      </div>
    </div>

    <div class="text-center mt-5">
      <router-link
        v-if="isAdmin"
        :to="{ name: 'MenuPage' }"
        type="submit"
        class="btn btn-outline-submit"
      >
        <iconify icon="fa-solid:home" class="fontsize-h4 mr-2" />
        กลับไปยังหน้ารายการ
      </router-link>
      <router-link
        v-else
        :to="{ name: 'AssessmentList' }"
        type="submit"
        class="btn btn-outline-submit"
      >
        <iconify icon="fa-solid:home" class="fontsize-h4 mr-2" />
        กลับไปยังหน้ารายการ
      </router-link>
    </div>

    <ModalAssessment :modalShow="isShowModal" @change="isShowModal = $event">
    </ModalAssessment>
  </div>
</template>

<script>
import { AssessmentService } from "@/services/main.service.js";
import ModalAssessment from "./ModalAssessment.vue";
import moment from "moment";

export default {
  name: "assessment-summary",
  components: {
    ModalAssessment,
  },
  data() {
    const breadcrumbs = [
      {
        to: "SurfaceForm",
        text: "แบบประเมินส่วนที่ 1",
      },
      {
        to: "SurfaceForm",
        text: "แบบประเมินส่วนที่ 2",
      },
      {
        to: "SurfaceForm",
        text: "แบบประเมินส่วนที่ 3",
      },
      {
        to: "AssessmentSummary",
        text: "ผลการประเมินคุณภาพน้ำ",
      },
    ];
    return {
      surveyID: 0,
      isAdmin: false,
      funcDate: moment,
      breadcrumbs: breadcrumbs,
      isShowModal: false,
      villageId: null,
      villageName: null,
      items: [],
      fields: [
        { key: "index", label: "ลำดับ", align: "center" },
        { key: "danNane", label: "ปัจจัย", align: "left" },
        { key: "score", label: "คะแนน", align: "left" },
        { key: "scorePercentage", label: "เปอร์เซนต์ (%)", align: "center" },
        { key: "statusText", label: "สถานะการประเมิน", align: "left" },
      ],
      summary: {
        score: null,
        percent: null,
        assessmentText: null,
        assessment: null,
      },
      Dan: [],
      series: [],
      chartResult: [],
      address: {},
      modifyDate: null,
      personCreated: {},
      personContact: {},
    };
  },
  async created() {
    const _router = this.$route;
    this.isAdmin = _router.matched.some((record) => record.meta.isAdmin);
    this.surveyID = this.$route.query.surveyID;

    this.getDetailDan();
    await this.getDetailSummary();

    let data = [];
    let chartResult = [];
    this.items.forEach((item) => {
      data.push(item.scorePercentage);

      chartResult.push({
        scorePercentage: item.scorePercentage,
        statusText: item.statusText,
        statusSymbol: item.assessment,
      });
    });

    this.series = [
      {
        data: data,
      },
    ];
    this.chartResult = [...chartResult];
  },
  methods: {
    getIcon(item) {
      if (item === "A") return "bi:emoji-heart-eyes-fill";
      else if (item === "B") return "bi:emoji-smile-fill";
      else if (item === "C") return "bi:emoji-wink-fill";
      else if (item === "D") return "bi:emoji-neutral-fill";
      else if (item === "F") return "bi:emoji-frown-fill";
    },
    getColorIcon(item) {
      if (item === "A") return "font-graph-green";
      else if (item === "B") return "font-graph-softgreen";
      else if (item === "C") return "font-graph-yellow";
      else if (item === "D") return "font-graph-orange";
      else if (item === "F") return "font-graph-rad";
    },
    webpagePrint() {
      //to print the current window
      window.print();
    },
    goBackToAssessment() {
      const param = {
        pwaTypeId: this.address.pwaTypeId,
        villageId: this.address.villageId,
        villageNo: this.address.villageNo,
        provinceId: this.address.provinceId,
        amphorId: this.address.amphorId,
        tambolId: this.address.tambolId,
      };
      this.$router.push({
        name: "AssessmentList",
        query: {
          ...param,
        },
      });
    },
    async getDetailSummary() {
      await AssessmentService.getSummary(this.surveyID)
        .then((res) => {
          this.address = res[0].dataAddress;
          this.modifyDate = res[0].modifyDate;
          this.personCreated = res[0].dataPersonCreate || {};
          this.personContact = res[0].dataPersonContact || {};

          res[0].dataSum.forEach((data, index) => {
            if (index < 5) {
              this.items.push({
                index: index + 1,
                danNane: data.danNane,
                score: data.score.toFixed(2),
                scorePercentage: data.scorePercentage.toFixed(2),
                statusText: data.statusText,
                assessment: data.statusTextSymbol,
              });
            } else {
              this.summary.score = data.score.toFixed(2);
              this.summary.percent = data.scorePercentage.toFixed(2);
              this.summary.assessmentText = data.statusText;
              this.summary.assessment = data.statusTextSymbol;
            }
          });
        })
        .catch((err) => {
          this.items = [];
        });
    },
    async getDetailDan() {
      await AssessmentService.getDan(this.surveyID)
        .then((res) => {
          this.Dan = res.dataDan;
        })
        .catch((err) => {
          this.Dan = [];
        });
    },
    viewSuggestion() {
      this.$utils.gotoSuggestion();
    },
  },
  computed: {
    title() {
      const result =
        (this.address.systemPlumbingName || "") +
        " หมู่ " +
        (this.address.villageNo || "-") +
        " ตำบล " +
        (this.address.tambolName || "-") +
        " อำเภอ " +
        (this.address.amphorName || "-") +
        " จังหวัด " +
        (this.address.provinceName || "-");

      return result;
    },
  },
};
</script>

<style lang="scss">
.footer-color {
  background-color: #faf2dd;
}

.bg-item {
  background-color: #ebf5f9;
}

.link-url {
  color: $systemblue;
  text-decoration: underline;
}
</style>
